import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../styles/themes/vad/borderRadius';
import { pxToRem } from '../../../../styles/themes/vad/constants';
import { fontSize } from '../../../../styles/themes/vad/font';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.common.BlackFadded,
    fontSize: fontSize.s0Rem,
    lineHeight: fontSize.s0Rem,
    borderRadius: borderRadius.b2Rem,
    display: 'inline-block',
    padding: `${pxToRem(7.12)} ${pxToRem(5.76)} ${pxToRem(5.66)} ${pxToRem(5.4)}`,
  },
  tagSign: {
    display: 'inline',
    ...theme.mixins.marginRight(pxToRem(3.79)),
    '& svg path': {
      fill: theme.palette.common.LightGrey,
    },
    '&:last-child': {
      ...theme.mixins.marginRight(pxToRem(0)),
    },
  },
  isActive: {
    '& svg path': {
      fill: theme.palette.common.white,
    },
  },
}));

export default useStyles;
