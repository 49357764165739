import React from 'react';
import useStyles from './style';
import DctSvgIcon from '../Svg';
import { numToKilo } from '../../../utils/numToKilo';

const ViewsCounterTag = (props) => {
  const classes = useStyles(props);
  let viewsNumb = Number(props.value);

  // dont show views if less than 50.
  if (viewsNumb > 50) {
    viewsNumb = numToKilo(viewsNumb, 1);
  } else {
    viewsNumb = 0;
  }
  if (!viewsNumb) {
    return null;
  }

  return (
    <div className={classes.root}>
      <span className={classes.viewIcon}>
        <DctSvgIcon name="vieweye" />
      </span>
      <span className={classes.txt}>
        {viewsNumb}
      </span>
    </div>
  )
};

export default ViewsCounterTag;
