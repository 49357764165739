import React from 'react';
import {
  NextArrow,
  PrevArrow,
} from '../../../components/molecules/CustomArrows';

export const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  lazyLoad: 'progressive',
  nextArrow: <NextArrow color="default" styleVariant={'circleVariant'} colorVariant={'whiteVariant'} />,
  prevArrow: <PrevArrow color="default" styleVariant={'circleVariant'} colorVariant={'whiteVariant'} />,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
      },
    },
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '24px',
        arrows: false,
        useTransform: false,
        useCSS: false,
        touchMove: false,
        infinite: false,
      },
    },
  ],
};
