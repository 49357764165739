import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import useStyles from './CategoryTag.style';
import Typography from '../Typography';
import { getDictionaryText } from '../../../utils/getDictionaryText';
import { TAG_TEXT } from '../../../../constants/index';

const CategoryTag = props => {
  const {
    className,
    text,
    isAuthorable,
    language,
    attributeFilter,
    analyticsData,
    isDescriptionTag,
    borderStyle,
    colorStyle,
    from = '',
  } = props;
  const { dataId, dataLink } = analyticsData || '';
  let { link } = props;
  if (!link || link === '#') {
    link = ''
  }
  const classes = useStyles({ borderStyle, colorStyle });

  const buildAttributeListingPageUrl = () => {
    const attributeListingPageUrl = getDictionaryText('attributeListingPageUrl');
    let filteredLink;
    if (attributeFilter) {
      filteredLink = `/${language}${attributeListingPageUrl}?${TAG_TEXT}=${attributeFilter}`;
    }
    return filteredLink;
  }

  const tagField = {
    value: text,
  };

  const tagVariant = from === 'favoritesNav' ? 'labelBlack2' : 'labelBlack'

  if (isAuthorable) {
    return (
      <>
        <Chip
          component="div"
          label={
            <span className={classes.chipLabel}>
              <Typography variant="labelBlack" component="span">
                <Text field={tagField} />
              </Typography>
            </span>
          }
          classes={{
            root: `${classes.root} ${className} ${classes.chipRoot}`,
          }}
        /></>
    );
  }

  const hrefLink = link || buildAttributeListingPageUrl();

  return (
    <React.Fragment>
      {isDescriptionTag && hrefLink ? (
        <Chip
          data-id={dataId}
          data-link={dataLink}
          label={
            <Typography variant={tagVariant} component="span">
              {text}
            </Typography>
          }
          component="a"
          href={hrefLink} //if link is explictly mentioned while calling category tag then it will redirect to the same url, else attribute lising
          classes={{
            root: `${classes.root} ${className}`,
          }}
          clickable
        />
      ) : !isDescriptionTag && !hrefLink ? (
        <Chip
          component="div"
          label={
            <Typography variant={tagVariant} component="span">
              {text}
            </Typography>
          }
          classes={{
            root: `${classes.root} ${className}`,
          }}
        />
      ) : <></>}
    </React.Fragment>
  );
};

CategoryTag.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  borderStyle: PropTypes.string,
  link: PropTypes.string,
  isAuthorable: PropTypes.bool,
  from: PropTypes.string,
  isDescriptionTag: PropTypes.bool,
};

CategoryTag.defaultProps = {
  className: '',
  link: '#',
  isAuthorable: false,
  isDescriptionTag: false,
  from: '',
  borderStyle: undefined,
  colorStyle: undefined,
};

export default React.memo(CategoryTag);
