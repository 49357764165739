import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => ({
  root: {
    '&.is-alone': {
      paddingBottom: pxToRem(10.35),
      [theme.breakpoints.up('lg')]: {
        paddingBottom: pxToRem(16),
      },
    },
  },
  text1: {
    ...theme.typography.body3Bold,
    color: theme.palette.common.Black4,
  },
  text2: {
    ...theme.typography.body3,
    color: theme.palette.common.Grey1,
    '&.has-dot': {
      '&:before': {
        content: '"•"',
        display: 'inline-block',
        ...theme.typography.labelRegular,
        color: theme.palette.common.Grey1,
        margin: `0 ${pxToRem(4)}`,
        position: 'relative',
        top: pxToRem(-1),
      },
      '&.is-he:before': {
        top: pxToRem(1),
      },
    },
  },

  text1Small: {
    ...theme.typography.labelBlack,
    color: theme.palette.common.black,
    // '&:before': {
    //   content: '"•"',
    //   display: 'inline-block',
    //   ...theme.typography.labelRegular,
    //   color: theme.palette.common.Grey1,
    //   margin: `0 ${pxToRem(4)}`,
    //   position: 'relative',
    //   top: pxToRem(-1),
    // },
  },
  text2Small: {
    ...theme.typography.labelRegular,
    color: theme.palette.common.Grey1,
    '&.has-dot': {
      '&:before': {
        content: '"•"',
        display: 'inline-block',
        ...theme.typography.labelRegular,
        color: theme.palette.common.Grey1,
        margin: `0 ${pxToRem(4)}`,
        position: 'relative',
        top: pxToRem(-1),
      },
      '&.is-he:before': {
        top: pxToRem(1),
      },
    },
  },

  isActive: {
    color: theme.palette.common.RedRibbon,
  },
}));

export default useStyles;
