import getAppConfig from '../global/utils/getAppConfig';

const config = getAppConfig();
const urls = {};

export default urls;

export const getFieldType = fieldUrl => {
  return fieldUrl && fieldUrl.substring(fieldUrl.lastIndexOf('/') + 1);
};
export const generatelayoutServicePath = (item, lang) => {
  return `${config.sitecoreApiHost}/sitecore/api/layout/render/jss?item=${item}&sc_lang=${lang}&sc_apikey=${config.sitecoreApiKey}`;
};

//Used for modying API static values with dynamic ones
// urlString- > 'trip-adviser/v1/api/partner/2.0/location/{location}?lang={language}`,
//paramObj = { location: '1234',language: 'en'}
//O/P -> 'trip-adviser/v1/api/partner/2.0/location/1234?lang='en'`,
export const modifyApi = (urlString, paramobj) => {
  let apiPath = urlString;
  if (paramobj) {
    for (let key in paramobj) {
      apiPath = apiPath.replace(`{${key}}`, paramobj[key]);
    }
  }
  return apiPath;
};
export const parseQueryParams = pathname => {
  const search = pathname.split('?')[1];
  if (search) {
    const queryList = search.split('&');
    return queryList.reduce((accu, curr) => {
      const [key, value] = curr.split('=');
      return { ...accu, [key]: value.split('#')[0] };
    }, {});
  }
  return {};
};

export const getUrlParam = param => {
  if (typeof window === 'undefined') return '';
  const params = new URL(window.location).searchParams;
  return params.get(param);
};

export const createUrlSearchParams = (query = {}, urlEncoded = false) => {
  const queryParams = [];
  const keys = Object.keys(query);
  for (let i = 0, l = keys.length; i < l; i += 1) {
    const qs = `${query[keys[i]]}`;
    const qsencoded = urlEncoded ? encodeURIComponent(qs) : qs;
    queryParams.push(`${keys[i]}=${qsencoded}`);
  }
  return queryParams.join('&');
};

export const buildUrl = options => {
  if (typeof options === 'object') {
    const { pathname, query, urlEncoded } = options;
    let url = pathname;
    if (typeof query === 'object') {
      url += `?${createUrlSearchParams(query, urlEncoded)}`;
    }
    return url;
  }
  return options;
};
