import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => ({
  circleVariant: {
    '&.slick-disabled': {
      opacity: 0.2,
    },
    '& button': {
      border: '1px solid #000',
      width: theme.spacing(8),
      height: theme.spacing(8),
      backgroundColor: theme.mixins.white,
      transition: 'all 0.3s ease-in-out',
      [theme.breakpoints.up('xl')]: {
        width: pxToRem(88),
        height: pxToRem(88),
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '& svg': {
        color: theme.mixins.Black(),
      },
      '&:hover': {
        backgroundColor: theme.mixins.Black(300),
      },
    },
  },
  whiteVariant: {
    '& button': {
      backgroundColor: theme.mixins.White(),
      borderColor: theme.mixins.White(),
      ':not(.slick-disabled) &': {
        '&:hover': {
          opacity: 1,
          backgroundColor: theme.mixins.White(),
          borderColor: theme.mixins.White(),
        },
        [theme.breakpoints.up('xl')]: {
          '&:hover': {
            opacity: 1,
            backgroundColor: theme.palette.common.Pompadour,
            borderColor: theme.palette.common.Pompadour,
            '& svg': {
              '& path': {
                fill: theme.mixins.White(),
              },
            },
          },
        },
      },
      '& svg': {
        '& path': {
          fill: theme.palette.common.Pompadour,
        },
      },
    },
  },
}));

export default useStyles;
