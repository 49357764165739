import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';


import useStyles from './style';
import Button from '../../atoms/Button';
import DctSvgIcon from '../../atoms/Svg';
import Badge from '../../atoms/Badge';

const FavoriteIcon = ({
  className,
  hasFavorite,
  isFavClicked,
  isMobile,
  favoritesCount,
  ...props
}) => {
  const classes = useStyles(className);

  const favoriteClickHandler = (e) => {
    e.preventDefault();
    if (props.onClickHandler) {
      props.onClickHandler(e)
    }
  }

  return (
    <>
      <Button
        iconOnly={true}
        aria-label="favorite button"
        onClick={(e) => favoriteClickHandler(e)}
        edge="start"
        className={clsx(
          classes.ctaBtn,
          classes.favoriteIcon,
          isFavClicked && !isMobile && classes.redfavoriteIcon,
        )}
      >
        <Badge badgeContent={favoritesCount} max={999} color="secondary">
          <DctSvgIcon
            name={`FavoriteBorderIcon`}
          // stroked={isFavClicked}
          />
        </Badge>
      </Button>
    </>

  );
};

FavoriteIcon.propTypes = {
  className: PropTypes.string
};

FavoriteIcon.defaultProps = {
  className: '',
};

export default FavoriteIcon;
