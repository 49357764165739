import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../styles/themes/vad/borderRadius';
import { mobileMedia, pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => ({
  colorSecondary: {
    backgroundColor: theme.mixins.AbuDhabiOrange(1000),
    color: theme.mixins.White(1000),
    marginTop: pxToRem(-4),
    fontWeight: 700,
    border: `2px solid ${theme.mixins.White()}`,
    padding: '2px 6px',
    minWidth: 0,
    lineHeight: 0,
    borderRadius: borderRadius.b999px,

    [mobileMedia]: {
      position: 'relative',
      alignSelf: 'center',
      ...theme.mixins.marginLeft(12),
      marginTop: '1px',
      minWidth: pxToRem(24),
    },
  },

  badgeAnchorOverride: {
    transform: 'none',
  },
}));

export default useStyles;
