// Turn numbers into kilo format.
export const numToKilo = (num, type = 0) => {
  let firstLimit = 1000;
  let decimalsDefault = 1;
  if (type === 1) {
    // This type will show K symbol after 9999
    // Otherwise default is 999
    firstLimit = 10000;
    // This type will also enforce decimale values only after reaching Million count
    // Otherwise default is after 999 decimals will be used.
    decimalsDefault = 0;
  }
  let returnVal = '';
  let maxOutput = 6;

  num = Math.round(num);

  if (num === null || num === undefined || num === '' || isNaN(num)) {
    return '';
  }

  const generateValue = (divisor, SI, decimals = 0) => {
    let generatedValue = parseFloat((num / divisor).toFixed(decimals)) + SI;
    // Remove decimal value if the return value is exceeding maxOutput.
    if (generatedValue.length > maxOutput) {
      generatedValue = parseFloat((num / divisor).toFixed(0)) + SI;
    }
    return generatedValue;
  }
  const addComma = (num) => {
    // add comma after each 3 digits
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  if (num >= firstLimit) {
    // this is to make sure valus after K like M must have 1 decimal value.
    if (!decimalsDefault) {
      decimalsDefault = 1;
    }
  }
  if (num < firstLimit) {
    returnVal = addComma(num);
  } else if (num < 1000000) {
    returnVal = generateValue(1000, 'K', decimalsDefault);
  } else if (num < 1000000000) {
    returnVal = generateValue(1000000, 'M', decimalsDefault);
  } else if (num < 1000000000000) {
    returnVal = generateValue(1000000000, 'B', decimalsDefault);
  } else if (num < 1000000000000000) {
    returnVal = generateValue(1000000000000, 'T', decimalsDefault);
  } else if (num < 1000000000000000000) {
    returnVal = generateValue(1000000000000000, 'P', decimalsDefault);
  }

  if (returnVal.split('.')[1] !== undefined) {

  }

  return returnVal;
}