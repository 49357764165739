import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => ({
  '@global': {
    '.c-collage-type-1 + [data-locator="Infobox"]': {
      marginTop: 0,
    },
  },
  secheading: {
    marginBottom: pxToRem(25),
    [theme.breakpoints.up('lg')]: {
      ...theme.mixins.marginLeft(0),
      marginBottom: pxToRem(32),
    },
  },
  headingSectionPeacockBlueColoTest: {
    color: theme.palette.text.sectionHeading,
  },
  root: {
    position: 'relative',
    ...theme.shape.componentSpacing,
    paddingBottom: theme.spacing(6),
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingBottom: theme.spacing(9),
    },
    [theme.breakpoints.down('md')]: {
      '&.is-rtl .slick-track': {
        left: 'auto !important',
      },
    },

    '& + [data-locator="CollageType1"] > *:first-child': {
      marginTop: '0px',
    },
    '& .MuiButtonBase-root.btnWithBorder': {
      [theme.breakpoints.down('md')]: {
        paddingTop: '0',
      },
    },
    '& .card-description': {
      flexGrow: '1',
      justifyContent: 'space-between',
    },
    '& .slick-track': {
      display: 'flex',
      paddingBottom: theme.spacing(3),
      ...theme.mixins.marginLR('0', '0'),
      ...theme.mixins.paddingLeft(pxToRem(24)), //this is to compensate the margin on slick-list
    },
    '& .slick-list': {
      overflowX: 'auto',
      overflowY: 'unset',
      [theme.breakpoints.down('md')]: {
        ...theme.mixins.paddingLeft('0 !important'),
      },
      [theme.breakpoints.up('lg')]: {
        overflowX: 'hidden',
        ...theme.mixins.marginLR(pxToRem(-24), pxToRem(-24)),
      },
    },
    '& .slick-slide': {
      display: 'flex',
      flex: '1',
      float: 'none',
      height: 'auto',
      cursor: 'pointer',
      backgroundColor: props => get(props, 'fields.backgroundColor'),
      transition: 'box-shadow .3s, background-color .3s',
      ...theme.mixins.marginLeft(pxToRem(0)),
      ...theme.mixins.marginRight(pxToRem(32)),
      [theme.breakpoints.up('lg')]: {
        ...theme.mixins.marginLeft(pxToRem(0)),
        ...theme.mixins.marginRight(pxToRem(24)),
      },
      '&:hover': {
        boxShadow: theme.shadows[1],
        backgroundColor: theme.mixins.White(),
        '& .card-description': {
          borderBottomColor: 'transparent',
        },
        '& h3': {
          color: theme.palette.common.SeaPort,
        },
        '& :not(.img-to-animate-wrapper) img, & .img-to-animate-wrapper': {
          transform: 'scale3d(1.1, 1.1, 1.1)',
        },
      },
      '& > div, & > div > div': {
        width: '100%',
        height: '100%',
      },
    },
    '& .slick-slide img': {
      height: '100%',
      width: '100%',
    },
    '& :not(.img-to-animate-wrapper) img, & .img-to-animate-wrapper': {
      transition: 'all 0.75s ease',
    },
    '& .slick-disabled': {
      opacity: theme.palette.action.disabledOpacity,
      pointerEvents: 'none',
    },
    '& .slick-prev, & .slick-next': {
      zIndex: '100',
      width: 'auto',
      height: 'auto',
      transition: 'opacity 0.5s ease-out',
      position: 'absolute',
    },
    '&:not(.Type1) .slick-prev > button, &:not(.Type1) .slick-next > button': {
      border: '1px solid #000',
      width: theme.spacing(8),
      height: theme.spacing(8),
      transition: 'all 0.3s ease-in-out',
      [theme.breakpoints.up('xl')]: {
        width: pxToRem(84),
        height: pxToRem(84),
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '& svg': {
        color: theme.mixins.Black(),
      },
      '&:hover': {
        backgroundColor: theme.mixins.Black(300),
      },
    },
    '& .slick-prev::before,& .slick-next::before': {
      content: "''",
    },
    '& .slick-prev': {
      top: '0',
      left: 'auto',
      ...theme.mixins.right('5.5%'),
      transform: 'none',
    },
    '& .slick-next': {
      ...theme.mixins.right('5.5%'),
      top: theme.spacing(16),
      [theme.breakpoints.up('xl')]: {
        top: theme.spacing(19.5),
      },
    },

    //Event Carousel Specific Overwites
    '&.isCollage': {
      background: theme.mixins.White(),
      paddingBottom: 0,
      marginBottom: 0,
      [theme.breakpoints.up('lg')]: {
        '& .MuiButton-root': {
          '&:hover': {
            '& .MuiButton-endIcon': {
              backgroundColor: `${theme.mixins.Black()} !important`,
              '& svg': {
                color: `${theme.mixins.White()}`,
              },
            },
          },
        },
      },
      '& .slick-slider': {
        [theme.breakpoints.up('lg')]: {
          ...theme.mixins.paddingLR('5.5%', '5.5%'),
        },
      },
      '& .slick-track': {
        [theme.breakpoints.up('lg')]: {
          margin: pxToRem(0),
        },
      },
      '& .slick-slide': {
        flex: '1',
        display: 'flex',
        float: 'none',
        height: 'auto',
        '& > div > div': {
          height: '100%',
        },
        '& .imageWrap': {
          height: '100%',
          maxHeight: pxToRem(229),
        },
        '& img': {
          [theme.breakpoints.up('lg')]: {
            maxHeight: pxToRem(229),
          },
        },
        '& .img-to-animate': {
          [theme.breakpoints.up('lg')]: {
            maxHeight: pxToRem(274.8), // added 20% to max height for paralax animation.
          },
          '& img': {
            [theme.breakpoints.up('lg')]: {
              maxHeight: 'none',
            },
          },
        },
      },
    },

    //Type 3 Carousel Specific Overwrites
    '&.Type3': {
      backgroundColor: props =>
        get(props, 'params.backgroundColor', theme.mixins.White()),
      paddingBottom: '0',
      [theme.breakpoints.up('lg')]: {
        marginBottom: theme.spacing(9),
      },
      '& .location': {
        '& span': {
          ...theme.palette.common.ellipsis(1),
        },
      },

      '&.no-cta': {
        '& h2': {
          marginBottom: theme.spacing(3),
          [theme.breakpoints.up('lg')]: {
            marginBottom: theme.spacing(5),
          },
        },
        '& .slick-prev, & .slick-next': {
          display: 'none',
        },
      },
      '& .slick-slider': {
        ...theme.mixins.paddingLR(0, `calc(5.5% - ${theme.spacing(4)})`),
        [theme.breakpoints.up('lg')]: {
          ...theme.mixins.paddingLR('5.5%', '5.5%'),
        },
      },
      '& .slick-slide': {
        [theme.breakpoints.up('lg')]: {
          ...theme.mixins.marginLR(pxToRem(12)),
          '&:not(.slide-active) .listing-card': {
            boxShadow: 'none',
          },
        },
      },
      '& .slick-list': {
        [theme.breakpoints.up('lg')]: {
          ...theme.mixins.marginLR(pxToRem(-12)),
        },
      },
      '& .slick-prev, & .slick-next': {
        top: '50%',
        transform: 'translateY(-50%)',
      },
      '& .slick-prev': {
        ...theme.mixins.left(-20),
        ...theme.mixins.right('auto'),
      },
      '& .slick-next': {
        ...theme.mixins.left('auto'),
        ...theme.mixins.right(-20),
      },
      '& .slick-track': {
        [theme.breakpoints.up('lg')]: {
          ...theme.mixins.paddingLeft(0),
        },
        '& .slick-slide': {
          '& h3': {
            ...theme.typography.cardTitle1, // as per long page design, changed card title styling to cardTitle1 from h5
          },
        },
      },
    },

    // Type 3 With Description Carousel Specific Overwrites
    '&.Type3WithDescription': {
      '& .collage-description': {
        minHeight: 0,
      },
      backgroundColor: props =>
        get(props, 'params.backgroundColor', theme.mixins.White()),
      paddingBottom: '0',
      marginTop: theme.spacing(5),
      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(9),
      },

      '& .alignment-type3description': {
        paddingBottom: '0',
        minHeight: pxToRem(0),
        '& .type3DescriptionPaddingAlign': {
          [theme.breakpoints.down('lg')]: {
            marginBottom: pxToRem(24),
          },
          [theme.breakpoints.up('lg')]: {
            marginBottom: pxToRem(32),
          },
        },
      },

      '& .location': {
        '& span': {
          ...theme.palette.common.ellipsis(1),
        },
      },
      '& .type3-description-wrapper': {
        '& .card-description': {
          paddingBottom: pxToRem(16),
        },
        '& .description-txt': {
          ...theme.palette.common.ellipsis('none'),
        },
        '& .MuiButton-sizeLarge': {
          fontSize: pxToRem(16),
        },
        '& .sitecorelink': {
          width: 'fit-content',
          marginTop: pxToRem(24),
        },
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: 'transparent',
          '& h3': {
            color: 'initial',
          },
          '& :not(.img-to-animate-wrapper) img, & .img-to-animate-wrapper': {
            transform: 'initial',
            '&:hover': {
              transform: 'scale3d(1.1, 1.1, 1.1)',
            },
          },
          '& .icon img': {
            transform: 'none',
          },
        },
      },

      '&.no-cta': {
        '& h2': {
          marginBottom: theme.spacing(3),
          [theme.breakpoints.up('lg')]: {
            marginBottom: theme.spacing(5),
          },
        },
        '& .slick-prev, & .slick-next': {
          display: 'none',
        },
      },
      '& .slick-slider': {
        ...theme.mixins.paddingLR(0, `calc(5.5% - ${theme.spacing(4)})`),
        [theme.breakpoints.up('lg')]: {
          ...theme.mixins.paddingLR('5.5%', '5.5%'),
        },
      },
      '& .slick-slide': {
        [theme.breakpoints.down('lg')]: {
          ...theme.mixins.marginRight(pxToRem(24)),
        },
        [theme.breakpoints.up('lg')]: {
          ...theme.mixins.marginRight(pxToRem(20)),
          ...theme.mixins.marginLR(pxToRem(12)),
          '&:not(.slide-active) .listing-card': {
            boxShadow: 'none',
          },
        },
        cursor: 'default',
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: 'transparent',
          '& .card-description': {
            borderBottomColor: 'transparent',
          },
          '& h3': {
            color: theme.palette.common.SeaPort,
          },
          '& :not(.img-to-animate-wrapper) img, & .img-to-animate-wrapper': {
            transform: 'scale3d(1.1, 1.1, 1.1)',
          },
        },
      },
      '& .slick-list': {
        [theme.breakpoints.up('lg')]: {
          ...theme.mixins.marginLR(pxToRem(-12)),
        },
      },
      '& .slick-prev, & .slick-next': {
        top: '50%',
        transform: 'translateY(-50%)',
      },
      '& .slick-prev': {
        left: -20,
        right: 'auto',
      },
      '& .slick-next': {
        left: 'auto',
        right: -20,
      },
      '& .slick-track': {
        [theme.breakpoints.up('lg')]: {
          ...theme.mixins.paddingLeft(0),
        },
      },
    },


    //Type 1 Carousel Specific Overwrites
    '&.Type1': {
      background: props => get(props, 'params.backgroundColor', theme.palette.common.Axolotl),
      color: props => get(props, 'params.theme') === 'Dark' ? theme.palette.common.black : theme.palette.common.white,
      paddingBottom: pxToRem(39),
      marginTop: pxToRem(88),
      marginBottom: pxToRem(88),
      [theme.breakpoints.down('md')]: {
        marginTop: pxToRem(0),
        marginBottom: pxToRem(48),
        paddingBottom: pxToRem(48),
      },
      '& > *': {
        zIndex: 3,
        '&.slick-slider': {
          zIndex: 2,
        },
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '-1px',
        left: 0,
        width: '100%',
        height: '75px',
        background: theme.mixins.White(),
        zIndex: 1,
        // below lg
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      },
      '& .card-description-flex': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '& .location-title-top': {
          ...theme.palette.common.ellipsis(1),
          color: props => get(props, 'params.theme') === 'Dark' ? theme.palette.text.sectionHeading : theme.palette.common.HalfDutchWhite,
        },
      },
      '& .sec-heading': {
        [theme.breakpoints.up('lg')]: {
          marginBottom: '12px',
        },
      },
      '& .background-supergraphics': {
        position: 'absolute',
        bottom: 0,
        ...theme.mixins.right(0),
        width: '80%',
        height: '100%',
        zIndex: 0,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage: (props) => `url(${get(props, 'fields.supergraphicImage.value.src', '')})`,
        [theme.breakpoints.down('md')]: {
          width: '100%',
          ...theme.mixins.left(0),
          maxWidth: 'none',
        },
      },
      '& .card-wrapper': {
        border: 'none',
        '&:hover': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      },
      '& .common-card-tag': {
        marginTop: pxToRem(25),
        [theme.breakpoints.down('md')]: {
          marginTop: '10px',
        },
      },
      '& .card-description': {
        color: props => get(props, 'params.theme') === 'Dark' ? theme.palette.common.black : theme.palette.common.white,
        justifyContent: 'flex-start',
        '& .common-card-tag-wrapper': {
          margin: 'auto 0 0 0',
        },
        // up lg
        [theme.breakpoints.down('md')]: {
          padding: '20px 0 0 0',
        },
      },
      '& .collage-description': {
        background: 'transparent',
        maxWidth: '62%',
        ...theme.mixins.right('auto'),
        ...theme.mixins.left('38.5%'),
        ...theme.mixins.paddingLeft(0),
        ...theme.mixins.paddingRight(pxToRem(333)),
        paddingTop: '100px',
        marginBottom: 0,
        paddingBottom: '20px',
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        minHeight: '326px',
        maxHeight: '370px',
        '@media (max-width: 1279px)': {
          ...theme.mixins.left('54%'),
          ...theme.mixins.paddingRight(pxToRem(16)),
          maxWidth: '46%',
        },
        // below lg
        [theme.breakpoints.down('md')]: {
          maxWidth: '100%',
          marginBottom: '0',
          paddingTop: pxToRem(48),
          minHeight: 0,
          paddingBottom: pxToRem(48),
          '& .sec-heading': {
            marginBottom: '0',
          },
        },
      },
      '& .slick-track': {
        paddingLeft: '0',
        paddingRight: '0',
        [theme.breakpoints.down('md')]: {
          ...theme.mixins.paddingLeft(pxToRem(24)),
          paddingBottom: '39px',
        },
      },
      '& .slick-slider': {
        // ...theme.mixins.paddingLR(0, `calc(5.5% - ${theme.spacing(4)})`),
        [theme.breakpoints.up('md')]: {
          ...theme.mixins.marginRight('-2px'),
        },
        [theme.breakpoints.up('lg')]: {
          ...theme.mixins.paddingRight('0'),
          ...theme.mixins.paddingLeft('5.5%'),
          overflow: 'visible',
        },
      },
      '& .slick-list': {
        paddingRight: 0,
        paddingLeft: 0,
        [theme.breakpoints.up('lg')]: {
          overflow: 'visible',
          ...theme.mixins.paddingRight(pxToRem(0)),
          ...theme.mixins.marginRight(pxToRem(-44)),
          ...theme.mixins.marginLeft(pxToRem(0)),
        },
      },
      '& .slick-prev, & .slick-next': {
        top: 'calc(50% + 30px)',
        transform: 'translateY(-50%)',
        '&.slick-disabled': {
          pointerEvents: 'auto',
        },
      },
      '& .slick-prev': {
        left: pxToRem(-20),
        right: 'auto',
        '@media (max-width: 1279px)': {
          left: pxToRem(-10),
        },
      },
      '& .slick-next': {
        left: 'auto',
        right: pxToRem(-20),
        '@media (max-width: 1279px)': {
          right: pxToRem(-10),
        },
      },
      '& .slick-slide': {
        ...theme.mixins.marginRight(pxToRem(48)),
        '&:hover': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          '& h3': {
            color: 'inherit',
          },
          '& .card-description': {
            [theme.breakpoints.up('lg')]: {
              backgroundColor: theme.mixins.White(100),
            },
          },
        },
        '& .carousel-picture': {
          height: '337px',
          [theme.breakpoints.up('lg')]: {
            height: '620px',
          },
          '& img, & video': {
            margin: 'auto 0 0 0',
            position: 'absolute',
            bottom: 0,
            ...theme.mixins.left(0),
            transform: 'translateZ(0)',
            height: '337px',
            width: '100%',
            objectFit: 'cover',
            transition: 'all 0.75s ease',
            [theme.breakpoints.up('lg')]: {
              height: '250px',
            },
          },
        },
        [theme.breakpoints.down('md')]: {
          ...theme.mixins.marginLeft(pxToRem(0)),
          ...theme.mixins.marginRight(pxToRem(48)),
        },
      },
      '&:not(.is-rtl)': {
        '& .collage-description': {
          '& h2': {
            [theme.breakpoints.up('md')]: {
              ...theme.palette.common.ellipsis(2),
            },
          },
        },
        [theme.breakpoints.up('lg')]: {
          '& .slick-slide': {
            transform: 'translateX(-100px)',
          },
          '& .slick-slide.slick-active, & .slick-slide.slick-active + .slick-slide, & .slick-slide.slick-active + .slick-slide + .slick-slide': {
            transform: 'translateX(0)',
          },
        },
        '& .slick-slide.slick-current': {
          '& .carousel-picture': {
            '& img, video': {
              marginTop: 'auto',
              [theme.breakpoints.up('lg')]: {
                height: '620px',
              },
            },
          },
        },
      },
      '&.is-rtl': {
        '& .collage-description': {
          [theme.breakpoints.down('md')]: {
            paddingTop: '30px',
          },
          '& h2': {
            [theme.breakpoints.up('md')]: {
              ...theme.palette.common.ellipsis(1),
            },
          },
        },

        '@media (min-width: 1280px)': {
          '& .slick-slide.slick-active + .slick-slide.slick-active + .slick-slide + .slick-slide': {
            transform: 'translateX(100px)',
          },
          '& .slick-slide.slick-current + .slick-slide + .slick-slide': {
            '& .carousel-picture': {
              '& img, video': {
                marginTop: 'auto',
                [theme.breakpoints.up('lg')]: {
                  height: '620px',
                },
              },
            },
          },
        },
        '@media (max-width: 1279px)': {
          '& .slick-slide.slick-active + .slick-slide.slick-active + .slick-slide': {
            transform: 'translateX(100px)',
          },
          '& .slick-slide.slick-current + .slick-slide': {
            '& .carousel-picture': {
              '& img, video': {
                marginTop: 'auto',
                [theme.breakpoints.up('lg')]: {
                  height: '620px',
                },
              },
            },
          },
        },
        '& .slick-prev, & .slick-next': {
          '&:before': {
            content: '""',
          },
        },
      },
    },
  },
  description: {
    background: 'none',
    position: 'static',
    padding: '0',
    width: '100%',
    height: 'auto',
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      width: '65.25%',
      top: '0',
      ...theme.mixins.right(0),
      zIndex: '10',
      ...theme.mixins.paddingLeft(pxToRem(48)),
      ...theme.mixins.paddingRight(theme.spacing(27)),
      paddingBottom: theme.spacing(8),
      paddingTop: theme.spacing(1),
      background:
        `linear-gradient(-180deg, ${theme.mixins.White()} 0%, ${theme.mixins.White(890)} 100%)`,
    },
  },
  descriptionType3: {
    paddingBottom: '0',
    [theme.breakpoints.up('lg')]: {
      paddingBottom: theme.spacing(5),
      minHeight: pxToRem(220),
    },
  },
  collageType1: {
  },
  headingContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headingBackground: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: props => {
      return `url(${get(props, 'fields.mobileImage.value.src', '/')})`;
    },
    [theme.breakpoints.up('lg')]: {
      backgroundImage: props => {
        return `url(${get(props, 'fields.desktopImage.value.src', '/')})`;
      },
    },
  },
  collageItems: {
    position: 'relative',
    top: theme.spacing(-6),
    [theme.breakpoints.up('lg')]: {
      top: theme.spacing(-11),
    },
  },
  headingSection: {
    color: theme.palette.text.sectionHeading,
    paddingBottom: theme.spacing(7),
    paddingTop: theme.spacing(6),
    ...theme.mixins.paddingRight(pxToRem(10)),
    '& .segment-title:before': {
      backgroundColor: theme.mixins.White(),
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(15),
      ...theme.mixins.paddingRight(0),
      '& .MuiButton-root.btnWithBorder': {
        borderTopColor: theme.mixins.White(200),
      },
    },
  },
  collageType1Logo: {
    ...theme.mixins.marginLeft(pxToRem(24)),
    ...theme.mixins.marginRight(pxToRem(24)),
    textAlign: theme.mixins.textRight(),
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(7),
    paddingTop: theme.spacing(6),
    '& img': {
      width: '118.44px',
      height: '33px',
    },
    [theme.breakpoints.up('lg')]: {
      ...theme.mixins.marginLeft('5.5%'),
      ...theme.mixins.marginRight('5.5%'),
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(15),
      '& img': {
        width: '262px',
        height: '73px',
      },
    },
  },
  discoverMore: {
    transform: `translateY(${theme.spacing(-6)})`,
  },
  rtlClass: {
    '&:not(.Type1) [dir="ltr"] .slick-prev::before,&:not(.Type1) [dir="ltr"] .slick-next::before': {
      content: "''",
    },
    '&.Type3 [dir="ltr"] .slick-prev': {
      right: 'auto',
      left: '-20px',
    },
    '&.Type3 [dir="ltr"] .slick-next': {
      right: '-20px',
      left: 'auto',
    },
    '& .slick-slider': {
      [theme.breakpoints.down('md')]: {
        direction: 'rtl',
      },
    },
  },
}));

export default useStyles;
