export const parseToLDJson = (collageType1Items) => {
  const output = collageType1Items.map(item => {
    const { fields } = item;
    const itemsObj = [
      {
        "@context": "https://schema.org",
        "@type": "ImageObject",
        "contentUrl": fields.desktopImage ? fields.desktopImage.value.src : fields.image.value.src
      }
    ]
    return [...itemsObj]
  });

  return JSON.stringify([].concat.apply([], output))
}