import React, { useState, useEffect } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { get, isEmpty } from 'lodash';
import { canUseDOM } from '../../../utils/canUseDOM';
import { modifyApi } from '../../../../utils/urls';
import { getTimeFromTimezone } from '../../../../utils/utility';
import fetchService from '../../../../services/FetchService';
import { getDictionaryText } from '../../../utils/getDictionaryText';
import moment from 'moment';
import useStyles from './style';

const OpenNowTag = props => {
  let placeId = get(props, 'googlePlaceId', '') || get(props, 'defaultGooglePlaceId', '');
  let language = get(props, 'language');
  let openNowText = get(props, 'openNowText', 'openNow');
  const classes = useStyles();
  const [openingObj, setOpeningObj] = useState({
    text: '',
    highlight: false,
  });
  const [addDot, setAddDot] = useState(false);
  let formatIsAM = true;

  useEffect(() => {
    placeId = get(props, 'googlePlaceId', '') || get(props, 'defaultGooglePlaceId', '');
    language = get(props, 'language');
    if (language === 'ar') {
      moment.locale('ar');
    } else {
      moment.locale('en');
    }
    if (
      language === 'it' ||
      language === 'fr' ||
      language === 'ru' ||
      language === 'he' ||
      language === 'zh') {
      formatIsAM = false;
    }
    const queryParam = language ? `?language=${language}` : '';
    const propsGooglePlaceAPI = get(props, 'googlePlacesAPI', '');
    if (canUseDOM && placeId && propsGooglePlaceAPI) {
      fetchService({
        url: modifyApi(propsGooglePlaceAPI, {
          placeId: `${placeId}${queryParam}`,
        }),
      })
        .then(response => {
          const { data } = response;
          handleResponseData(data && data.result);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [
    get(props, 'googlePlaceId', ''),
    get(props, 'defaultGooglePlaceId', ''),
    get(props, 'language'),
  ]);

  // Extract AM/PM from time string
  const getAMPM = (time) => {
    let itemTime = 0;
    if (formatIsAM) {
      itemTime = time.replace(/\d+/g, '')
        .replaceAll(':', '')
        .replaceAll('–', '')
        .replaceAll(' ', '');
    }
    return itemTime;
  };

  // Add AM/PM in open time if missing (close time is always expected to have AM/PM)
  const addAMPM = (openTime, closeTime) => {
    if (formatIsAM) {
      let itemOpenTimeHasAmPm = openTime.replace(/\d+/g, '')
        .replaceAll(':', '')
        .replaceAll('–', '')
        .replaceAll(' ', '');
      if (!itemOpenTimeHasAmPm) {
        // get AM/PM from closing time.
        let itemCloseTimeGetAmPm = getAMPM(closeTime);
        // Add AM/PM to open time
        openTime = `${openTime} ${itemCloseTimeGetAmPm}`;
      }
    }
    return openTime;
  };

  const getOpenCloseHrsFromString = (weekDaysHours) => {
    if (weekDaysHours.indexOf(' ') > -1) {
      return (weekDaysHours ?
        weekDaysHours.substring(weekDaysHours.indexOf(' ')).toLocaleLowerCase() :
        '');
    } else {
      return 'isClose';
    }
  }

  const addInListOfOpenAndClose = (...openCloseHrsAll) => {
    let openAndCloseList = [];
    openCloseHrsAll.forEach(openCloseHrs => {
      if (openCloseHrs === 'isClose') {
        openAndCloseList.push('isClose');
        return;
      }
      const multipleOpenCloseHrs = openCloseHrs.split(',');
      let initialAMPM = '';
      let day = 0;
      multipleOpenCloseHrs.forEach(items => {
        // item data roughly like this: 9:00 am – 10:30 pm || 9:00 – 10:30 pm || 12:00–3:30 م
        // Split the item data into open and close time
        items = items.split('–');
        if (items) {
          items[0] = addAMPM(items[0], items[1]);
          let loopIndex = 0;
          for (let item of items) {
            if (initialAMPM === '') {
              initialAMPM = getAMPM(item);
            } else if (initialAMPM != getAMPM(item)) {
              day++;
              initialAMPM = getAMPM(item);
            } else if (moment(item, `hh:mm ${formatIsAM && 'AM'}`).toString().match('00:00:00') ||
              (loopIndex % 2 === 1 && !moment(items[0], `hh:mm ${formatIsAM && 'AM'}`).isBefore(moment(item, `hh:mm ${formatIsAM && 'AM'}`))) ||
              moment(items[0], `hh:mm ${formatIsAM && 'AM'}`).toString() === moment(item, `hh:mm ${formatIsAM && 'AM'}`).toString()) {
              day++;
            }
            items[loopIndex] = {
              day,
              time: items[loopIndex],
            };
            loopIndex++;
          }

          openAndCloseList.push({
            openTime: items[0],
            closeTime: items[1],
          });
        } else {
          openAndCloseList.push('isClose');
          return;
        }
      });
      // }
    });
    return openAndCloseList;
  };

  const handleResponseData = res => {
    /*
      If business status is closed temporarily or permanently, show relative message.
    */
    if (get(res, 'business_status')) {
      let businessStatus = '';
      if (res.business_status === 'CLOSED_TEMPORARILY') {
        businessStatus = getDictionaryText('closedTemporarily');
      } else if (res.business_status === 'CLOSED_PERMANENTLY') {
        businessStatus = getDictionaryText('closedPermanently');
      }
      if (businessStatus) {
        setOpeningObj({
          text: businessStatus,
          highlight: true,
        });
        return;
      }
    }

    let weekDaysHours = get(res, 'opening_hours.weekday_text');
    if (weekDaysHours) {
      /*
        Create a single array with open and close array sequence for today.
      */

      // making weekDyasHours array to have current day info at top.
      let currentDay = new Date().getDay();
      if (weekDaysHours) {
        if (language === 'ar') {
          // Arabic weekDays start from Saturday
          // So we need to make Monday be on top of the list first.
          weekDaysHours = weekDaysHours && [
            ...weekDaysHours.slice(2, 7),
            ...weekDaysHours.slice(0, 2),
          ];
        }
        if (language === 'he') {
          // Hebrew weekDays start from Sunday
          // So we need to make Monday be on top of the list first.
          weekDaysHours = weekDaysHours && [
            ...weekDaysHours.slice(1, 7),
            ...weekDaysHours.slice(0, 1),
          ];
        }
        weekDaysHours = weekDaysHours && [
          ...weekDaysHours.slice(currentDay - 1, 7),
          ...weekDaysHours.slice(0, currentDay - 1),
        ];
      }

      // for 24 hours format
      if (
        weekDaysHours[0].match('24') ||
        (res.opening_hours.periods.length === 1 && res.opening_hours.periods[0].open.time === '0000')
      ) {
        let open24HrsText = weekDaysHours[0] || get(res, 'opening_hours.weekday_text')[0];
        open24HrsText = getOpenCloseHrsFromString(open24HrsText);

        // make first letter capital.
        if (open24HrsText.trim() === 'open 24 hours') {
          open24HrsText = 'Open 24 hours';
        }

        setOpeningObj({
          text: open24HrsText,
          highlight: false,
        });
        return;
      }

      // extract open and close time from weekDaysHours today.
      // and store them in openAndCloseList.
      let openCloseHrsToday = getOpenCloseHrsFromString(weekDaysHours[0]);
      // let openCloseHrsNextDay = getOpenCloseHrsFromString(weekDaysHours[1]);
      let openAndCloseList = addInListOfOpenAndClose(openCloseHrsToday);
      /*
        Loop through openAndCloseList array and show when the venue will open or close
        depending on if the venue is closed or open respectively.
      */
      let targetTZCurrentMoment = getTimeFromTimezone(), // Get Abu Dhabi current time
        targetTZCurrentTime = targetTZCurrentMoment.split(' ')[4], // Extract time i.e 18:56:24 hh:mm:ss
        openTime = '',
        closeTime = '',
        openNowTxt = '';

      // If the venue is currently close check which is the nearest opening time

      let loopDay = 0;
      if (res.opening_hours && !res.opening_hours.open_now) {
        for (let thisTime of openAndCloseList) {
          if (thisTime.openTime.time === '  ') {
            openTime = 'isClose';
          }
          // Check if current time is before open time
          if (moment(loopDay + ' ' + targetTZCurrentTime, 'd hh:mm:ss').isBefore(moment(thisTime.openTime.day + ' ' + thisTime.openTime.time, `d hh:mm ${formatIsAM && 'AM'}`))) {
            // If current time is before open time then get the open time
            openTime = thisTime.openTime.time.replaceAll(' ', '');
            break;
          } else {
            loopDay += 2;
          }
        };
      }

      // If the venue is currently open check which is the nearest closing time
      loopDay += 1;
      if (res.opening_hours && res.opening_hours.open_now) {
        for (let thisTime of openAndCloseList) {
          // Check if current time is before close time
          if (moment(loopDay + ' ' + targetTZCurrentTime, 'd hh:mm:ss').isBefore(moment(thisTime.closeTime.day + ' ' + thisTime.closeTime.time, `d hh:mm ${formatIsAM && 'AM'}`))) {
            // If current time is before open time then get the open time
            closeTime = thisTime.closeTime.time.replaceAll(' ', '');
            break;
          } else {
            loopDay += 2;
          }
        };
      }

      // Check if business is open now & set dictionary text accordingly
      const openingHoursText =
        res.opening_hours && res.opening_hours.open_now
          ? getDictionaryText(openNowText)
          : getDictionaryText('closed');
      setAddDot(res.opening_hours && res.opening_hours.open_now
        ? false
        : true);

      if (!openTime && !closeTime) {
        openNowTxt = openingHoursText;
      } else {
        openNowTxt = `${openingHoursText}|${getDictionaryText('till')} ${closeTime}`;
      }
      if (!closeTime) {
        openNowTxt = openTime;
      }

      const operationalStatus =
        openingHoursText === getDictionaryText(openNowText)
          ? openNowTxt
          : `${openingHoursText}|${openTime && getDictionaryText('opensAt')} ${openTime}`;
      setOpeningObj({
        text: operationalStatus,
        highlight: openingHoursText === getDictionaryText(openNowText) ? false : true,
      });
    };
  };

  const { text, highlight } = openingObj || {};
  let [firstText, ...remainingText] = text && text.split('|');
  remainingText = remainingText.toString().trim();

  if (!isEmpty(firstText)) {
    props.setReviewSpace(false);
    return (
      <div className={`${classes.root} ${props.isAlone ? 'is-alone' : ''}`}>
        <span className={`${openNowText == "Open" ? classes.text1Small : classes.text1} text ${highlight ? classes.isActive : ''}`} >
          <Text field={{ value: firstText }}></Text>
        </span>
        {remainingText.length > 0 && (
          <span className={`${openNowText == "Open" ? classes.text2Small : classes.text2} text ${addDot && 'has-dot'} ${language === 'he' ? 'is-he' : ''}`}>
            <Text field={{ value: (addDot ? '' : ' ') + remainingText }} ></Text>
          </span>
        )}
      </div>
    );
  } else {
    props.setReviewSpace(true);
    return <React.Fragment />;
  }
};

export default OpenNowTag;
