import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => ({
  ratingWrap: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    ...theme.mixins.marginLeft(theme.spacing(2)),
    '& svg': {
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      ...theme.mixins.marginRight(theme.spacing(0.25)),
    },
    '& span': {
      marginTop: pxToRem(-2),
      marginBottom: pxToRem(8),
    },
  },
}));

export default useStyles;
