import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../styles/themes/vad/borderRadius';
import { pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => ({
  ctaBtn: {
    width: pxToRem(80),
    borderLeft: '1px solid transparent',
    borderRadius: borderRadius.b0,
    padding: pxToRem(28),
    cursor: 'pointer',
    ...theme.mixins.marginLeft(0),
    [theme.breakpoints.up('lg')]: {
      width: 'auto',
      padding: pxToRem(12),
    },
    '& .MuiIconButton-label': {
      [theme.breakpoints.down('md')]: {
        height: pxToRem(28),
      },
    },
  },
  favoriteIcon: {
    color: 'inherit',
    [theme.breakpoints.up('lg')]: {
      transition: 'background-color 0.3s ease-in-out',
      border: '2px solid',
      borderRadius: borderRadius.circle,
      // borderColor: theme.mixins.AbuDhabiOrange(1000),
    },
    '@media (min-width: 1024px) and (max-width: 1599px)': {
      padding: '0.5rem',
    },
    '&:hover': {
      [theme.breakpoints.up('lg')]: {
        backgroundColor: theme.mixins.Black(200),
      },
    },
    '&:active': {
      borderColor: theme.mixins.AbuDhabiOrange(1000),
    },
  },
  favoriteRightMargin: {
    ...theme.mixins.marginLR(0, theme.spacing(7.2)),
  },
  redfavoriteIcon: {
    color: theme.mixins.AbuDhabiOrange(1000),
  },
  redfilledfavoriteIcon: {
    '& svg': {
      path: theme.mixins.AbuDhabiOrange(1000),
    }
  },
  favIcon: {
    ...theme.mixins.right(pxToRem(12)),
    position: 'absolute',
    '& svg': {
      width: "20px",
      height: "18px"
    }
  },
}));

export default useStyles;
