import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../styles/themes/vad/borderRadius';
import { pxToRem } from '../../../../styles/themes/vad/constants';
import { fontSize } from '../../../../styles/themes/vad/font';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.common.BlackFadded,
    borderRadius: borderRadius.b2Rem,
    display: 'inline-block',
    padding: `${pxToRem(7)} ${pxToRem(10)} ${pxToRem(7)} ${pxToRem(10)}`,
    color: theme.palette.common.White,
    verticalAlign: 'middle',
    fontSize: pxToRem(0),
    lineHeight: fontSize.s0Rem,
  },
  viewIcon: {
    verticalAlign: 'middle',
    ...theme.mixins.marginRight(pxToRem(4.36)),
    '& svg': {
      verticalAlign: 'middle',
    },
  },
  txt: {
    verticalAlign: 'middle',
    ...theme.typography.labelBold,
    lineHeight: 0,
  },
}));

export default useStyles;
