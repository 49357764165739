import React from 'react';
import SvgIcon from '../../../components/atoms/Svg';
import useStyles from './style';
import Typography from '../../../components/atoms/Typography';

const Rating = ({ rating, className }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {rating && (
        <div className={`${classes.ratingWrap} ${className}`}>
          <SvgIcon name={'StarIcon'} />
          <Typography variant="labelRegular" component="span">
            {rating}
          </Typography>
        </div>
      )}
    </React.Fragment>
  );
};

export default Rating;
