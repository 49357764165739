import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';

const CustomAvatar = props => {
  const { className, children, ...other } = props;
  return (
    <Avatar className={className} {...other}>
      {children}
    </Avatar>
  );
};

CustomAvatar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomAvatar;
