import React from 'react';
import clsx from "clsx"
import useStyles from './style';
import Typography from '../Typography';
import { getProcessedEventDate } from '../../../utils/dateTimeFunctions/dateTimeFunctions';

const SingleDate = ({ day, month, year }) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant={"body4Bold"} component="span">
        {day} &nbsp;
      </Typography>
      <Typography
        component="span"
        variant={"labelLight"}
        classes={{ root: clsx(classes.ltGray, classes.monthTag) }}
      >
        {month} {year}
      </Typography>
    </>
  )
}

const DateLabel = props => {
  const classes = useStyles();
  const { startDate, endDate, language } = props;
  const sdate = startDate && startDate !== "0001-01-01T00:00:00" ?
    getProcessedEventDate(startDate, language) : null;
  const edate = endDate && endDate !== "0001-01-01T00:00:00" ?
    getProcessedEventDate(endDate, language) : null;

  if (!sdate && !edate) {
    return <span></span>
  }
  if (sdate && edate) {
    return (
      <div className={classes.dateWrap}>
        <SingleDate {...sdate} />
        <Typography variant={"body2Bold"} classes={{ root: classes.mx8 }} component="span">
          -
        </Typography>
        <SingleDate {...edate} />
      </div>
    )
  } else {
    return (
      <div className={classes.dateWrap}>
        <SingleDate {...sdate} />
      </div>
    )
  }
};

export default DateLabel;
