import React from 'react';
import PropTypes from 'prop-types';
import BadgeMUI from '@material-ui/core/Badge';
import useStyles from './style';

const Badge = ({ icon, className, children, ...other }) => {
  const classes = useStyles();

  return (
    <BadgeMUI
      classes={{
        colorSecondary: classes.colorSecondary,
        anchorOriginTopLeftRectangle: classes.badgeAnchorOverride,
      }}
      {...other}
    >
      {children}
    </BadgeMUI>
  );
};

Badge.propTypes = {
  className: PropTypes.string,
};

Badge.defaultProps = {
  className: '',
};

export default Badge;
