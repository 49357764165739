import { makeStyles } from '@material-ui/core/styles';
import { mobileMedia, pxToRem } from '../../../../styles/themes/vad/constants';

export default makeStyles(theme => ({
  listItem: {
    position: 'relative',
    padding: `${pxToRem(16)} ${pxToRem(0)}`,
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.common.DisabledGrey}`,
    cursor: 'pointer',
    '& a, .listItemContainer': {
      width: '100%',
      display: 'flex',
    },

    '& .rating-img': {
      width: 'auto',
      maxHeight: pxToRem(13),
    },
    '& .rating-sec': {
      display: 'flex',
      alignItems: 'center',
      marginTop: pxToRem(6),
    },
    '& .open-close-sec': {
      display: 'flex',
    },
  },
  listItemTextWrap: {
    paddingLeft: pxToRem(16),
    paddingRight: pxToRem(16),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [mobileMedia]: {
      width: 'calc(100% - 95px)',
    },
  },
  ltGray: {
    color: theme.palette.common.UnselectedColor,
  },
  monthTag: {
    textTransform: 'uppercase',
  },
  pr12: {
    ...theme.mixins.paddingRight(12),
  },
  mt4: {
    marginTop: pxToRem(4),
  },
  mx8: {
    marginLeft: pxToRem(8),
    marginRight: pxToRem(8),
  },
  dateWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    marginBottom: pxToRem(4),
  },
  tagsWrapper: {
    marginTop: pxToRem(8),
  },
  categoryTag: {
    textTransform: 'uppercase',
    marginRight: pxToRem(8),
    marginLeft: 0,
    marginTop: pxToRem(8),
    maxWidth: '100%',
    '& .MuiTypography-labelBlack2': {
      ...theme.palette.common.ellipsis(1),
      maxWidth: pxToRem(120),
    },
  },
  eventTag: {
    color: theme.mixins.SeaGreen(),
    border: theme.borders.DarkTurquoiseBorder,
    '&:hover': {
      background: theme.mixins.SeaGreen(100),
    },
  },
  listTag: {
    cursor: 'pointer',
    '&:hover': {
      background: theme.mixins.CategortTagColor(100),
    },
  },
  ratingImageWithLogo: {
    display: 'flex',
    '& .MuiSvgIcon-root': {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  },
  totalReviews: {
    padding: '0',
    color: theme.palette.text.tertiary1,
  },
  ratingTripAdvisor: {
    display: 'inline-flex',
    background: theme.palette.background.transparent,
    ...theme.mixins.marginLeft(pxToRem(-5)),
    marginTop: pxToRem(-1),
  },
  width95pc: {
    width: 'calc(100% - 24px)',
    ...theme.mixins.paddingLeft(24),
    [theme.breakpoints.down('md')]: {
      borderBottom: 0,
    },

    '& picture': {
      '& img': {
        backgroundSize: '90% auto',
      },
    },

  },
}));
