import React from 'react';
import { withNamespaces } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';
import SharedMultiCardContent from '../.././../components/organism/SharedMultiCardcontent/SharedMultiCardContent';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import { COMPONENT_NAME } from './locators';
import {
  getPageName,
  getComponentName,
} from '../../../utils/getPageComponentInfo';

const MultiCardCarousel = props => {
  const updatedProps = {
    ...props,
    ...{
      fields: {
        ...props.fields,
        ...{
          pageName: getPageName(props),
          componentName: getComponentName(props),
        },
      },
    },
  };
  return (
    <SharedMultiCardContent {...updatedProps} data-locator={COMPONENT_NAME} />
  );
};

MultiCardCarousel.propTypes = {
  carouselItems: PropTypes.array,
  title: PropTypes.object,
  description: PropTypes.object,
  segmentName: PropTypes.object,
  cta: PropTypes.object,
};

MultiCardCarousel.defaultProps = {
  carouselItems: [],
  title: {},
  description: {},
  segmentName: {},
  cta: {},
};

export default withErrorBoundary(
  withSitecoreContext()(withNamespaces()(MultiCardCarousel))
);
