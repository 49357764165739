
import React from 'react';
import useStyles from './style';
import DctSvgIcon from '../Svg';

const PriceRangeTag = (props) => {
  const classes = useStyles(props);
  const maxLen = 3;
  if (props.value && props.value.length > 0) {
    const desiredLen = props.value.length > maxLen ? maxLen : props.value.length;
    return (
      <div className={classes.root}>
        {Array.from(Array(maxLen), (x, i) => i).map((key) => {
          return (
            <div
              key={key}
              className={`${classes.tagSign} ${key <= (desiredLen - 1) ? classes.isActive : ''}`}
            >
              <DctSvgIcon name="DollarSign" titleDisabled={key <= (desiredLen - 1) ? '' : true} />
            </div>
          );
        })}
      </div>
    )
  } else {
    return null;
  }
};

export default PriceRangeTag;