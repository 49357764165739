import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../styles/themes/vad/borderRadius';
import { pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.categoryTagaction.disabledBackground,
    borderRadius: borderRadius.b3,
    border: props => props.borderStyle || theme.borders.categoryTagBorder,
    color: props => props.colorStyle || theme.mixins.CategortTagColor(),
    marginTop: theme.spacing(1),
    textTransform: 'uppercase',
    maxWidth: '100%',
    '& [class^="MuiChip-label"]': {
      paddingLeft: pxToRem(8),
      paddingRight: pxToRem(8),
    },
    '&.categoryTagWhite': {
      border: theme.borders.categoryTagBorderWhite,
      color: theme.palette.common.white,
    },
    '&.categoryTagHalfDutchWhite': {
      borderColor: theme.palette.common.HalfDutchWhite,
      color: theme.palette.common.HalfDutchWhite,
      borderRadius: borderRadius.b3,
    },
  },
  chipRoot: {
    display: 'inline-flex',
    height: theme.spacing(4),
    alignItems: 'center',
    justifyContent: 'center',
  },
  chipLabel: {
    whiteSpace: 'nowrap',
  },
}));

export default useStyles;
