import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../styles/themes/vad/borderRadius';
import { pxToRem } from '../../../../styles/themes/vad/constants';
import { palette } from '../../../../styles/themes/vad/palette';
import {
  EVENT_TYPE,
} from '../../../../constants';
export default makeStyles(theme => ({
  heartWrap: {
    position: 'relative',
    zIndex: 10,
    cursor: 'pointer',
  },
  absoluteHeartWrap: {
    position: 'absolute',
    zIndex: 10,
    cursor: 'pointer',
    ...theme.mixins.right(0),
  },
  circularHeartWrap: {
    position: 'relative',
    zIndex: 10,
    cursor: 'pointer',
    width: '20px',
    height: '28px',
    [theme.breakpoints.down('md')]: {
      width: '28px',
      marginTop: '6px',
      borderRadius: '50%',
      background: '#E4E8EA',
      ...theme.mixins.marginRight('6px'),
    }
  },
  mapHeartIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '28px',
  },
  heartIcon: {
    position: 'absolute',
    top: 0,
    ...theme.mixins.right(0),
    marginTop: 19,
    ...theme.mixins.marginRight(18),
    '& svg': {
      width: '20px',
      height: '18px',
    },
  },
  sliderHeartIcon: {
    position: 'absolute',
    top: 0,
    ...theme.mixins.right(0),
    marginTop: 28,
    ...theme.mixins.marginRight(18),
    '& svg': {
      width: '20px',
      height: '18px',
    },
  },
  heartNavIcon: {
    position: 'absolute',
    top: 0,
    ...theme.mixins.right(0),
    ...theme.mixins.marginRight(-2),
    '& svg': {
      width: '20px',
      height: '18px',
    },
  },
  addToFavWrapperDescMin: {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.marginRight(18),
    [theme.breakpoints.down('md')]: {
      ...theme.mixins.marginRight(12),
      boxSizing: 'content-box',
    },
  },
  addToFavWrapperDesc: {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.marginRight(18),

    [theme.breakpoints.down('md')]: {
      ...theme.mixins.marginRight(12),
      paddingBottom: pxToRem(24),
      boxSizing: 'content-box',
    },
  },
  addToFavIconWrapDesc: {
    width: '2.5rem',
    height: '2.5rem',
    display: 'flex',
    borderRadius: borderRadius.circle,
    alignItems: 'center',
    background: palette.background.BGGray179(200),
    justifyContent: 'center',
  },
  descHeartIcon: {
    position: 'relative',
    top: 0,
    verticalAlign: 'middle',
    alignItems: 'center',
    height: '18px',
    marginTop: 0,
    '& svg': {
      width: '20px',
      height: '18px',
    },
  },
  heartIconSelected: {
    '& svg': {
      '& path': {
        fill: ({ contentType }) => contentType === EVENT_TYPE && theme.mixins.DarkTurquoise(),
      },
    },
  },
  addToFavText: {
    opacity: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    color: ({ contentType }) => contentType === EVENT_TYPE ? theme.mixins.DarkTurquoise() : theme.mixins.AbuDhabiOrange(),
    whiteSpace: 'nowrap',
  },
  showFavText: {
    opacity: 1,
  },
  '@global': {
    '.addToFavTextInStickyTitle': {
      height: '100%',
      position: 'relative',
      ...theme.mixins.marginLeft(16),
    },
    '.sticky:not(.ghostdiv-reached)': {
      '& .hideFavText': {
        '& .addToFavTextInStickyTitle': {
          display: 'none',
        },

        '& .ml4': {
          ...theme.mixins.marginLeft(pxToRem(4)),
        },
      },
    },
  },
}));
